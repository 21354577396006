import React, { useState } from 'react';
import Slider from 'react-slick';

import '../../../styles/slick/slick.css';
import '../../../styles/slick/slick-theme.css';
import css from './Sections.module.css';
import img1 from '../../../assets/1.png';
import img2 from '../../../assets/2.png';
import img3 from '../../../assets/3.png';
import img4 from '../../../assets/4.png';
import img5 from '../../../assets/5.png';
import img6 from '../../../assets/6.png';
import classNames from 'classnames';

const popularInstruments = [
  { img: img1, text: 'Accordions', search: '?pub_category=accordions' },
  { img: img2, text: 'Banjos', search: '?pub_category=banjos' },
  { img: img3, text: 'Bodhráns', search: '?pub_category=bodhrans' },
  { img: img5, text: 'Concertinas', search: '?pub_category=concertinas' },
  { img: img6, text: 'Fiddles', search: '?pub_category=fiddles' },
  { img: img1, text: 'Accordions', search: '?pub_category=accordions' },
  { img: img2, text: 'Banjos', search: '?pub_category=Banjos' },
  { img: img3, text: 'Bodhráns', search: '?pub_category=bodhrans' },
];

function PopularInstruments(props) {
  const { onRedirectToSearchPage, intl, sliderSettings } = props;

  const isWindowDefined = typeof window !== 'undefined';
  const isMobileLayout = isWindowDefined && window.innerWidth < 768;

  const shopInstrumentsTitle = intl.formatMessage({
    id: 'PopularInstruments.shopInstrumentsTitle',
  });

  function NextArrow(props) {
    const { className, style, onClick } = props;
    return <div className={className} style={{ ...style }} onClick={onClick} />;
  }

  function PrevArrow(props) {
    const { className, style, onClick } = props;
    return <div className={className} style={{ ...style }} onClick={onClick} />;
  }

  if (isMobileLayout) {
    Object.assign(sliderSettings, {
      dots: false,
      arrows: true,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
    });
  }

  return (
    <div className={classNames(css.popularSessions, css.sectionPadding, css.shopInstrumentSec)}>
      <div className={css.fixedWidthContainer}>
        <div className={css.sectionHead}>
          <div className={css.leftSec}>
            <h2 className={css.sectionHeading}>{shopInstrumentsTitle}</h2>
          </div>
        </div>
        <Slider {...sliderSettings}>
          {popularInstruments.map((item, index) => (
            <div key={index} className={css.sliderItem} onClick={onRedirectToSearchPage}>
              <div className={css.itemImg}>
                <img src={item.img} alt={item.text} />
              </div>
              <p>{item.text}</p>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

PopularInstruments.defaultProps = {
  sliderSettings: {
    dots: true,
    arrows: false,
    infinite: false,
    slidesToShow: 5.5, // Display 4 items on desktop
    slidesToScroll: 4, // Scroll 1 item at a time
    variableWidth: false,
    focusOnSelect: false,
    centerMode: false,
    responsive: [
      {
        breakpoint: 1280, // Adjust settings for smaller viewports
        settings: {
          slidesToShow: 5.8,
          variableWidth: false,
        },
      },
      {
        breakpoint: 1128, // Adjust settings for smaller viewports
        settings: {
          slidesToShow: 5.5,
          variableWidth: false,
        },
      },
      {
        breakpoint: 1024, // Adjust settings for smaller viewports
        settings: {
          slidesToShow: 5.2,
          variableWidth: false,
        },
      },
      {
        breakpoint: 991, // Adjust settings for smaller viewports
        settings: {
          slidesToShow: 4.9,
          variableWidth: false,
        },
      },
      {
        breakpoint: 768, // Adjust settings for smaller viewports
        settings: {
          slidesToShow: 4.5,
          variableWidth: false,
        },
      },
      {
        breakpoint: 600, // Adjust settings for smaller viewports
        settings: {
          slidesToShow: 3.8,
          variableWidth: false,
        },
      },
      {
        breakpoint: 580, // Adjust settings for smaller viewports
        settings: {
          slidesToShow: 3.4,
          variableWidth: false,
        },
      },
      {
        breakpoint: 480, // Adjust settings for smaller viewports
        settings: {
          slidesToShow: 2.8,
          variableWidth: false,
        },
      },
      {
        breakpoint: 414, // Adjust settings for smaller viewports
        settings: {
          slidesToShow: 2.5,
          variableWidth: false,
        },
      },
    ],
  },
};

export default PopularInstruments;
