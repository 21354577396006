import React from 'react';
import { Heading, Icons } from '../../../components';
import heroBg from '../../../assets/hero-banner.png';
import { createResourceLocatorString } from '../../../util/routes';
import routeConfiguration from '../../../routing/routeConfiguration';

import css from './SectionHero.module.css';

function SectionHero(props) {
  const { intl, onRedirectToSearchPage } = props;

  const heroTitle = intl.formatMessage({ id: 'SectionHero.title' });
  const heroDescription = intl.formatMessage({ id: 'SectionHero.description' });
  const browseTitle = intl.formatMessage({ id: 'SectionHero.browseTitle' });

  return (
    <div className={css.heroContainer}>
      <div className={css.heroBg}>
        <img src={heroBg} alt="Trad Market | Passion, Shared" />
      </div>
      <div className={css.heroContent}>
        <Heading as="h1" rootClassName={css.heading}>
          {heroTitle}
        </Heading>
        <p className={css.para}>{heroDescription}</p>
        <button className={css.browseBtn} type="button" onClick={onRedirectToSearchPage}>
          {browseTitle}
          <Icons name="rightArrow" />
        </button>
      </div>
    </div>
  );
}

export default SectionHero;
