import React, { useState } from 'react';
import { Icons } from '../../../components';
import ReactPlayer from 'react-player';
import videoWrapper from '../../../assets/vdo.png';
import playIcon from '../../../assets/play-button.svg';

import css from './Sections.module.css';

function SectionHowItWorks(props) {
  const { intl } = props;

  const [playing, setPlaying] = useState(false);

  const handlePlay = () => {
    setPlaying(true);
  };

  const title = intl.formatMessage({
    id: 'SectionHowItWorks.title',
  });
  const description = intl.formatMessage({
    id: 'SectionHowItWorks.description',
  });
  const visitShop = intl.formatMessage({
    id: 'SectionHowItWorks.visitShop',
  });

  return (
    <div className={css.howWorksSec}>
      <div className={css.fixedWidthContainer}>
        <div className={css.contentSec}>
          <h1 className={css.heading}>{title}</h1>
          <p className={css.paragraph}>{description}</p>
          <a className={css.visitLink} href="/">
            {visitShop} <Icons name="rightArrow" />
          </a>
        </div>
        <div className={css.vdoSec}>
          <div className="player-wrapper" onClick={handlePlay}>
            <ReactPlayer
              url="https://www.youtube.com/embed/JL5KWcDMMBA?si=qMWQ1Me4RBICRdcR&amp;start=10"
              light={!playing ? videoWrapper : false}
              playing={playing}
              playIcon={
                <button>
                  <img src={playIcon} alt="Play" />
                </button>
              }
              controls={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SectionHowItWorks;
