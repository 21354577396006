import React from 'react';
import css from './Sections.module.css';
import about from '../../../assets/about-trad.png';

function SectionAboutUs() {
  return (
    <div className={css.aboutUsSec}>
      <div className={css.fixedWidthContainer}>
        <div className={css.vdoSec}>
          <img className={css.vdoImg} src={about} alt="" />
        </div>
        <div className={css.contentSec}>
          <h1 className={css.heading}>About Trad Market</h1>
          <p className={css.paragraph}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas varius, risus nec
            congue ullamcorper, urna ligula aliquam quam, a bibendum ante quam non justo. Sed
            euismod auctor bibendum. Vestibulum vitae purus ut metus volutpat vulputate.
          </p>
          <p>
            Pellentesque in feugiat lorem. Proin sollicitudin euismod sem, eu dictum metus fringilla
            nec. Vestibulum eu bibendum ex, sed blandit ante. Sed et risus a quam vestibulum
            ultricies. Integer vel augue ut risus fringilla venenatis eu non ex. Aenean tincidunt
            libero a ante ullamcorper, sed vehicula quam tristique.{' '}
          </p>
        </div>
      </div>
    </div>
  );
}

export default SectionAboutUs;
