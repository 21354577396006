import React from 'react';
import classNames from 'classnames';
import { ListingCard } from '../../../components';
import Slider from 'react-slick';

import css from './Sections.module.css';
import '../../../styles/slick/slick-theme.css';
import '../../../styles/slick/slick.css';

function ExploreSection(props) {
  const { listings, intl, sliderSettings } = props;

  const justInTitle = intl.formatMessage({
    id: 'ExploreSection.justIn',
  });

  const isWindowDefined = typeof window !== 'undefined';
  const isMobileLayout = isWindowDefined && window.innerWidth < 768;

  if (isMobileLayout) {
    Object.assign(sliderSettings, {
      dots: false,
    });
  }

  return (
    <div className={classNames(css.popularSessions, css.sectionPadding, css.justInSec)}>
      <div className={css.fixedWidthContainer}>
        <div className={css.sectionHead}>
          <div className={css.leftSec}>
            <h2 className={css.sectionHeading}>{justInTitle}</h2>
          </div>
        </div>
        <div className={css.sectionListings}>
          <div className={css.halfSlider}>
            <Slider {...sliderSettings}>
              {listings.map(l => (
                <ListingCard
                  className={css.listingCard}
                  key={l?.id?.uuid}
                  listing={l}
                  setActiveListing={() => {}}
                />
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
}

ExploreSection.defaultProps = {
  sliderSettings: {
    dots: true,
    arrows: false,
    infinite: false,
    autoplay: false,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 4, // Display 4 items on desktop
    slidesToScroll: 1, // Scroll 1 item at a time
    variableWidth: false,
    focusOnSelect: false,
    centerMode: false,
    responsive: [
      {
        breakpoint: 1500, // Adjust settings for smaller viewports
        settings: {
          slidesToShow: 4,
          variableWidth: false,
        },
      },
      {
        breakpoint: 1300, // Adjust settings for smaller viewports
        settings: {
          slidesToShow: 4,
          variableWidth: false,
        },
      },
      {
        breakpoint: 992, // Adjust settings for smaller viewports
        settings: {
          slidesToShow: 3,
          variableWidth: false,
        },
      },
      {
        breakpoint: 580, // Adjust settings for smaller viewports
        settings: {
          slidesToShow: 2,
          variableWidth: false,
        },
      },
      {
        breakpoint: 480, // Adjust settings for smaller viewports
        settings: {
          slidesToShow: 1,
          variableWidth: false,
        },
      },
    ],
  },
};

export default ExploreSection;
